<template>
    <div class="flex items-center rounded-full bg-blue-50 border border-blue-100 pl-3 pr-1 py-1 text-xs cursor-pointer" :class="{ 'bg-green-100 border-green-200': active }" @click="$emit('click')">
        <p class="mr-1">
            <slot name="label" />
        </p>
        <span class="px-2 py-1 bg-blue-100 rounded-full" :class="{ 'bg-green-200': active }">
            <slot name="count" />
        </span>
    </div>
</template>
<script>
export default {
    props: {
        active: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
